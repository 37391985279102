@import "../../assets/scss/theme.scss";
@import "../../assets/scss/responsive.scss";

// GLOBAL CSS FOR PAGES

body,
html {
    height: 100vh;
    width: 100vw;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    z-index: 1000;
}
::-webkit-scrollbar-track {
    z-index: 1000;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
::-webkit-scrollbar-track-piece {
    z-index: 1000;
}
::-webkit-scrollbar-thumb {
    z-index: 1000;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $tertiary;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: $tertiary-opaque-3;
}

.row{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.row-center{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.column{
    display: inline-flex;
    flex-direction: column;
    width: -webkit-fill-available;
    width: 100%;
}
.column-center{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: -webkit-fill-available;
    width: 100%;
}

.page-wrapper {
    background-color: $primary-background;
    height: 100%;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-top: 50px;
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-section {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px;
    margin-bottom: 20px;
}

.page-subsection {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px;
    margin-bottom: 20px;
}

.page-title {
    color: $primary-text;
    font-size: $T2;
    margin: 0px;
}

.page-subtitle {

    &-1{
        color: $primary-text;
        font-size: $T3;
        margin: 0px;
    }
    &-2{
        color: $primary-text;
        font-size: $T4;
        margin: 0px;
    }
}

.page-text {
    color: $primary-text;
    font-size: $T5;
    margin: 0px;
    &-small{
        color: $primary-text;
        font-size: $T6;
        margin: 0px;
    }
}

.textbox {
    &-small{
        max-width: $small-textbox-maxwidth;
    }
    &-medium{
        max-width: $medium-textbox-maxwidth;
    }
    &-large{
        max-width: $large-textbox-maxwidth;
    }
}

.m {
    &-t{
        &-10{
            margin-top: 10px;
        }
        &-20{
            margin-top: 20px;
        }
        &-40{
            margin-top: 40px;
        }
        &-60{
            margin-top: 60px;
        }
        &-5vh{
            margin-top: 5vh;
        }
        &-10vh{
            margin-top: 10vh;
        }
        &-20vh{
            margin-top: 20vh;
        }
        &-40vh{
            margin-top: 40vh;
        }
        &-60vh{
            margin-top: 60vh;
        }
    }
    &-r{
        &-10{
            margin-right: 10px;
        }
        &-20{
            margin-right: 20px;
        }
        &-40{
            margin-right: 40px;
        }
        &-60{
            margin-right: 60px;
        }
    }
    &-b{
        &-10{
            margin-bottom: 10px;
        }
        &-20{
            margin-bottom: 20px;
        }
        &-40{
            margin-bottom: 40px;
        }
        &-60{
            margin-bottom: 60px;
        }
    }
    &-l{
        &-10{
            margin-left: 10px;
        }
        &-20{
            margin-left: 20px;
        }
        &-40{
            margin-left: 40px;
        }
        &-60{
            margin-left: 60px;
        }
    }
}

.p {
  &-t{
      &-10{
          padding-top: 10px;
      }
      &-20{
          padding-top: 20px;
      }
      &-40{
          padding-top: 40px;
      }
      &-60{
          padding-top: 60px;
      }
      &-5vh{
          padding-top: 5vh;
      }
      &-10vh{
          padding-top: 10vh;
      }
      &-20vh{
          padding-top: 20vh;
      }
      &-40vh{
          padding-top: 40vh;
      }
      &-60vh{
          padding-top: 60vh;
      }
  }
  &-r{
      &-10{
          padding-right: 10px;
      }
      &-20{
          padding-right: 20px;
      }
      &-40{
          padding-right: 40px;
      }
      &-60{
          padding-right: 60px;
      }
  }
  &-b{
      &-10{
          padding-bottom: 10px;
      }
      &-20{
          padding-bottom: 20px;
      }
      &-40{
          padding-bottom: 40px;
      }
      &-60{
          padding-bottom: 60px;
      }
  }
  &-l{
      &-10{
          padding-left: 10px;
      }
      &-20{
          padding-left: 20px;
      }
      &-40{
          padding-left: 40px;
      }
      &-60{
          padding-left: 60px;
      }
  }
}

@media only screen and (max-width: 1000px) {
    .page-title {
        font-size: $T2-1000;
    }

    .page-subtitle {

        &-1{
            font-size: $T3-1000;
        }
        &-2{
            font-size: $T4-1000;
        }
    }

    .page-text {
        font-size: $T5-1000;
        &-small{
            font-size: $T6-1000;
        }
    }

    .textbox {
        &-small{
            max-width: $small-textbox-maxwidth-1000;
        }
        &-medium{
            max-width: $medium-textbox-maxwidth-1000;
        }
        &-large{
            max-width: $large-textbox-maxwidth-1000;
        }
    }
}

@media only screen and (max-width: 800px) {

    .page-title {
        font-size: $T2-800;
    }

    .page-subtitle {

        &-1{
            font-size: $T3-800;
        }
        &-2{
            font-size: $T4-800;
        }
    }

    .page-text {
        font-size: $T5-800;
        &-small{
            font-size: $T6-800;
        }
    }

    .textbox {
        &-small{
            max-width: $small-textbox-maxwidth-800;
        }
        &-medium{
            max-width: $medium-textbox-maxwidth-800;
        }
        &-large{
            max-width: $large-textbox-maxwidth-800;
        }
    }
}

@media only screen and (max-width: 600px) {

    .page-title {
        font-size: $T2-600;
    }

    .page-subtitle {

        &-1{
            font-size: $T3-600;
        }
        &-2{
            font-size: $T4-600;
        }
    }

    .page-text {
        font-size: $T5-600;
        &-small{
            font-size: $T6-600;
        }
    }

    .textbox {
        &-small{
            max-width: $small-textbox-maxwidth-600;
        }
        &-medium{
            max-width: $medium-textbox-maxwidth-600;
        }
        &-large{
            max-width: $large-textbox-maxwidth-600;
        }
    }
}

@media only screen and (max-width: 400px) {
    .page-title {
        font-size: $T2-400;
    }

    .page-subtitle {

        &-1{
            font-size: $T3-400;
        }
        &-2{
            font-size: $T4-400;
        }
    }

    .page-text {
        font-size: $T5-400;
        &-small{
            font-size: $T6-400;
        }
    }

    .textbox {
        &-small{
            max-width: $small-textbox-maxwidth-400;
        }
        &-medium{
            max-width: $medium-textbox-maxwidth-400;
        }
        &-large{
            max-width: $large-textbox-maxwidth-400;
        }
    }
}
