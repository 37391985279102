
$primary: #0170FF;
$secondary: #DFDFDF;
$tertiary: #6EBF4B;
$error: #ff0000;
$warning: #ffc000;
$success: #37b400;
$info: #0058e9;
$link: #02A7F0;
$disabled: #f5f5f5;

$primary-opaque-1: rgba(1, 112, 255, .1);
$primary-opaque-3: rgba(1, 112, 255, .3);
$primary-opaque-5: rgba(1, 112, 255, .5);
$primary-opaque-8: rgba(1, 112, 255, .8);

$secondary-opaque-1: rgba(223, 223, 223, .1);
$secondary-opaque-3: rgba(223, 223, 223, .3);
$secondary-opaque-5: rgba(223, 223, 223, .5);
$secondary-opaque-8: rgba(223, 223, 223, .8);

$tertiary-opaque-1: rgba(110, 191, 75, .1);
$tertiary-opaque-3: rgba(110, 191, 75, .3);
$tertiary-opaque-5: rgba(110, 191, 75, .5);
$tertiary-opaque-8: rgba(110, 191, 75, .8);

$error-opaque-1: rgba(255, 0, 0, .1);
$error-opaque-3: rgba(255, 0, 0, .3);
$error-opaque-5: rgba(255, 0, 0, .5);
$error-opaque-8: rgba(255, 0, 0, .8);

$warning-opaque-1: rgba(255, 192, 0, .1);
$warning-opaque-3: rgba(255, 192, 0, .3);
$warning-opaque-5: rgba(255, 192, 0, .5);
$warning-opaque-8: rgba(255, 192, 0, .8);

$success-opaque-1: rgba(55, 180, 0, .1);
$success-opaque-3: rgba(55, 180, 0, .3);
$success-opaque-5: rgba(55, 180, 0, .5);
$success-opaque-8: rgba(55, 180, 0, .8);

$info-opaque-1: rgba(0, 88, 233, .1);
$info-opaque-3: rgba(0, 88, 233, .3);
$info-opaque-5: rgba(0, 88, 233, .5);
$info-opaque-8: rgba(0, 88, 233, .8);

$link-opaque-1: rgba(2, 167, 240, .1);
$link-opaque-3: rgba(2, 167, 240, .3);
$link-opaque-5: rgba(2, 167, 240, .5);
$link-opaque-8: rgba(2, 167, 240, .8);

$disabled-opaque-1: rgba(245, 245, 245, .1);
$disabled-opaque-3: rgba(245, 245, 245, .3);
$disabled-opaque-5: rgba(245, 245, 245, .5);
$disabled-opaque-8: rgba(245, 245, 245, .8);


$primary-text: #000000;
$secondary-text: #FFFFFF;
$tertiary-text: #FFFFFF;
$error-text: #ff0000;
$warning-text: #ffc000;
$success-text: #37b400;
$info-text: #0058e9;
$link-text: #02A7F0;

$primary-text-opaque-1: rgba(0, 0, 0, .1);
$primary-text-opaque-3: rgba(0, 0, 0, .3);
$primary-text-opaque-5: rgba(0, 0, 0, .5);
$primary-text-opaque-8: rgba(0, 0, 0, .8);

$secondary-text-opaque-1: rgba(255, 255, 255, .1);
$secondary-text-opaque-3: rgba(255, 255, 255, .3);
$secondary-text-opaque-5: rgba(255, 255, 255, .5);
$secondary-text-opaque-8: rgba(255, 255, 255, .8);

$white-background: #FFFFFF;
$white-background-hover: #F6F6F6;
$black-background: #000000;
$primary-background: #F2F2F2;

$white-background-1: rgba(255, 255, 255, .1);
$white-background-3: rgba(255, 255, 255, .3);
$white-background-5: rgba(255, 255, 255, .5);
$white-background-8: rgba(255, 255, 255, .8);

$black-background-1: rgba(0, 0, 0, .1);
$black-background-3: rgba(0, 0, 0, .3);
$black-background-5: rgba(0, 0, 0, .5);
$black-background-8: rgba(0, 0, 0, .8);

$primary-background-1: rgba(242, 242, 242, .1);
$primary-background-3: rgba(242, 242, 242, .3);
$primary-background-5: rgba(242, 242, 242, .5);
$primary-background-8: rgba(242, 242, 242, .8);