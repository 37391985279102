// FONT SIZES
// =================================
$fs-10: 10px;
$fs-11: 11px;
$fs-12: 12px;
$fs-13: 13px;
$fs-14: 14px;
$fs-15: 15px;
$fs-16: 16px;
$fs-17: 17px;
$fs-18: 18px;
$fs-19: 19px;
$fs-20: 20px;
$fs-21: 21px;
$fs-22: 22px;
$fs-23: 23px;
$fs-24: 24px;
$fs-25: 25px;
$fs-26: 26px;
$fs-27: 27px;
$fs-28: 28px;
$fs-29: 29px;
$fs-30: 30px;
$fs-31: 31px;
$fs-32: 32px;

.fs {
    &-10 {
        font-size: $fs-10;
    }
    &-11 {
        font-size: $fs-11;
    }
    &-12 {
        font-size: $fs-12;
    }
    &-13 {
        font-size: $fs-13;
    }
    &-14 {
        font-size: $fs-14;
    }
    &-15 {
        font-size: $fs-15;
    }
    &-16 {
        font-size: $fs-16;
    }
    &-17 {
        font-size: $fs-17;
    }
    &-18 {
        font-size: $fs-18;
    }
    &-19 {
        font-size: $fs-19;
    }
    &-20 {
        font-size: $fs-20;
    }
    &-21 {
        font-size: $fs-21;
    }
    &-22 {
        font-size: $fs-22;
    }
    &-23 {
        font-size: $fs-23;
    }
    &-24 {
        font-size: $fs-24;
    }
    &-25 {
        font-size: $fs-25;
    }
    &-26 {
        font-size: $fs-26;
    }
    &-27 {
        font-size: $fs-27;
    }
    &-28 {
        font-size: $fs-28;
    }
    &-29 {
        font-size: $fs-29;
    }
    &-30 {
        font-size: $fs-30;
    }
    &-31 {
        font-size: $fs-31;
    }
    &-32 {
        font-size: $fs-32;
    }
    &-1e{
        font-size: 1em;
    }
}

// common value
// =================================

$cval: 5px;

// Padding
// =================================
.p {
    &-a {
        &-0 {
            padding: 0px;
        }
        &-1p {
            padding: 1px;
        }
        &-2p {
            padding: 2px;
        }
        &-3p {
            padding: 3px;
        }
        &-4p {
            padding: 4px;
        }
        &-1 {
            padding: ($cval * 1);
        }
        &-2 {
            padding: ($cval * 2);
        }
        &-3 {
            padding: ($cval * 3);
        }
        &-4 {
            padding: ($cval * 4);
        }
        &-5 {
            padding: ($cval * 5);
        }
        &-6 {
            padding: ($cval * 6);
        }
        &-7 {
            padding: ($cval * 7);
        }
        &-8 {
            padding: ($cval * 8);
        }
        &-9 {
            padding: ($cval * 9);
        }
        &-10 {
            padding: ($cval * 10);
        }
    }
    &-t {
        &-2pe {
            padding-top: 2%;
        }
        &-0 {
            padding-top: 0px;
        }
        &-1p {
            padding-top: 1px;
        }
        &-2p {
            padding-top: 2px;
        }
        &-3p {
            padding-top: 3px;
        }
        &-4p {
            padding-top: 4px;
        }
        &-1 {
            padding-top: ($cval * 1);
        }
        &-2 {
            padding-top: ($cval * 2);
        }
        &-3 {
            padding-top: ($cval * 3);
        }
        &-4 {
            padding-top: ($cval * 4);
        }
        &-5 {
            padding-top: ($cval * 5);
        }
        &-6 {
            padding-top: ($cval * 6);
        }
        &-7 {
            padding-top: ($cval * 7);
        }
        &-8 {
            padding-top: ($cval * 8);
        }
        &-9 {
            padding-top: ($cval * 9);
        }
        &-10 {
            padding-top: ($cval * 10);
        }
    }
    &-r {
        &-0 {
            padding-right: 0px;
        }
        &-1p {
            padding-right: 1px;
        }
        &-2p {
            padding-right: 2px;
        }
        &-3p {
            padding-right: 3px;
        }
        &-4p {
            padding-right: 4px;
        }
        &-7p {
            padding-right: 7px;
        }
        &-1 {
            padding-right: ($cval * 1);
        }
        &-2 {
            padding-right: ($cval * 2);
        }
        &-3 {
            padding-right: ($cval * 3);
        }
        &-4 {
            padding-right: ($cval * 4);
        }
        &-5 {
            padding-right: ($cval * 5);
        }
        &-6 {
            padding-right: ($cval * 6);
        }
        &-7 {
            padding-right: ($cval * 7);
        }
        &-8 {
            padding-right: ($cval * 8);
        }
        &-9 {
            padding-right: ($cval * 9);
        }
        &-10 {
            padding-right: ($cval * 10);
        }
        &-3pe {
            padding-right: 3%;
        }
    }
    &-b {
        &-0 {
            padding-bottom: 0px;
        }
        &-1p {
            padding-bottom: 1px;
        }
        &-2p {
            padding-bottom: 2px;
        }
        &-3p {
            padding-bottom: 3px;
        }
        &-4p {
            padding-bottom: 4px;
        }
        &-1 {
            padding-bottom: ($cval * 1);
        }
        &-2 {
            padding-bottom: ($cval * 2);
        }
        &-3 {
            padding-bottom: ($cval * 3);
        }
        &-4 {
            padding-bottom: ($cval * 4);
        }
        &-5 {
            padding-bottom: ($cval * 5);
        }
        &-6 {
            padding-bottom: ($cval * 6);
        }
        &-7 {
            padding-bottom: ($cval * 7);
        }
        &-8 {
            padding-bottom: ($cval * 8);
        }
        &-9 {
            padding-bottom: ($cval * 9);
        }
        &-10 {
            padding-bottom: ($cval * 10);
        }
        &-11 {
            padding-bottom: ($cval * 11);
        }
        &-32p {
            padding-bottom: 32px;
        }
        &-80p {
            padding-bottom: 80px;
        }
        &-160p {
            padding-bottom: 160px;
        }
    }
    &-l {
        &-0 {
            padding-left: 0px;
        }
        &-1p {
            padding-left: 1px;
        }
        &-2p {
            padding-left: 2px;
        }
        &-3p {
            padding-left: 3px;
        }
        &-4p {
            padding-left: 4px;
        }
        &-7p {
            padding-left: 7px;
        }
        &-1 {
            padding-left: ($cval * 1);
        }
        &-2 {
            padding-left: ($cval * 2);
        }
        &-3 {
            padding-left: ($cval * 3);
        }
        &-4 {
            padding-left: ($cval * 4);
        }
        &-5 {
            padding-left: ($cval * 5);
        }
        &-6 {
            padding-left: ($cval * 6);
        }
        &-7 {
            padding-left: ($cval * 7);
        }
        &-8 {
            padding-left: ($cval * 8);
        }
        &-9 {
            padding-left: ($cval * 9);
        }
        &-10 {
            padding-left: ($cval * 10);
        }
        &-1pe {
            padding-left: 1%;
        }
        &-2pe {
            padding-left: 2%;
        }
        &-4pe {
            padding-left: 4%;
        }
        &-13pe {
            padding-left: 13%;
        }
    }
}

// MARGIN
// =================================
.m {
    &-a {
        margin: auto;
        &-0 {
            margin: 0px;
        }
        &-1p {
            margin: 1px;
        }
        &-2p {
            margin: 2px;
        }
        &-3p {
            margin: 3px;
        }
        &-4p {
            margin: 4px;
        }
        &-1 {
            margin: ($cval * 1);
        }
        &-2 {
            margin: ($cval * 2);
        }
        &-3 {
            margin: ($cval * 3);
        }
        &-4 {
            margin: ($cval * 4);
        }
        &-5 {
            margin: ($cval * 5);
        }
        &-6 {
            margin: ($cval * 6);
        }
        &-7 {
            margin: ($cval * 7);
        }
        &-8 {
            margin: ($cval * 8);
        }
        &-9 {
            margin: ($cval * 9);
        }
    }
    &-t {
        &-0 {
            margin-top: 0px;
        }
        &-1p {
            margin-top: 1px;
        }
        &-2p {
            margin-top: 2px;
        }
        &-3p {
            margin-top: 3px;
        }
        &-4p {
            margin-top: 4px;
        }
        &-7p {
            margin-top: 7px;
        }
        &-8p {
            margin-top: 8px;
        }
        &-10p {
            margin-top: 10px;
        }
        &-12p {
            margin-top: 12px;
        }
        &-1 {
            margin-top: ($cval * 1);
        }
        &-2 {
            margin-top: ($cval * 2);
        }
        &-3 {
            margin-top: ($cval * 3);
        }
        &-4 {
            margin-top: ($cval * 4);
        }
        &-5 {
            margin-top: ($cval * 5);
        }
        &-6 {
            margin-top: ($cval * 6);
        }
        &-7 {
            margin-top: ($cval * 7);
        }
        &-8 {
            margin-top: ($cval * 8);
        }
        &-9 {
            margin-top: ($cval * 9);
        }
        &-23p {
            margin-top: 23px;
        }
        &-32p {
            margin-top: 32px;
        }
        &-2pm {
            margin-top: -2px;
        }
        &-5pm {
            margin-top: -5px;
        }
        &-14pm {
            margin-top: -14px;
        }
        &-20pm {
            margin-top: -20px;
        }
        &-108pm {
            margin-top: -108px;
        }
    }
    &-r {
        &-0 {
            margin-right: 0px;
        }
        &-1p {
            margin-right: 1px;
        }
        &-2p {
            margin-right: 2px;
        }
        &-3p {
            margin-right: 3px;
        }
        &-4p {
            margin-right: 4px;
        }
        &-5p {
            margin-right: 5px;
        }
        &-1 {
            margin-right: ($cval * 1);
        }
        &-2 {
            margin-right: ($cval * 2);
        }
        &-3 {
            margin-right: ($cval * 3);
        }
        &-4 {
            margin-right: ($cval * 4);
        }
        &-5 {
            margin-right: ($cval * 5);
        }
        &-6 {
            margin-right: ($cval * 6);
        }
        &-7 {
            margin-right: ($cval * 7);
        }
        &-8 {
            margin-right: ($cval * 8);
        }
        &-9 {
            margin-right: ($cval * 9);
        }
    }
    &-b {
        &-0 {
            margin-bottom: 0px;
        }
        &-1p {
            margin-bottom: 1px;
        }
        &-2p {
            margin-bottom: 2px;
        }
        &-3p {
            margin-bottom: 3px;
        }
        &-4p {
            margin-bottom: 4px;
        }
        &-8p {
            margin-bottom: 8px;
        }
        &-1 {
            margin-bottom: ($cval * 1);
        }
        &-2 {
            margin-bottom: ($cval * 2);
        }
        &-3 {
            margin-bottom: ($cval * 3);
        }
        &-4 {
            margin-bottom: ($cval * 4);
        }
        &-5 {
            margin-bottom: ($cval * 5);
        }
        &-6 {
            margin-bottom: ($cval * 6);
        }
        &-7 {
            margin-bottom: ($cval * 7);
        }
        &-8 {
            margin-bottom: ($cval * 8);
        }
        &-9 {
            margin-bottom: ($cval * 9);
        }
        &-12 {
            margin-bottom: ($cval * 12);
        }
        &-48p {
            margin-bottom: 48px;
        }
    }
    &-l {
        &-0 {
            margin-left: 0px;
        }
        &-1p {
            margin-left: 1px;
        }
        &-2p {
            margin-left: 2px;
        }
        &--2p {
            margin-left: -2px;
        }
        &-3p {
            margin-left: 3px;
        }
        &-4p {
            margin-left: 4px;
        }
        &-1 {
            margin-left: ($cval * 1);
        }
        &-2 {
            margin-left: ($cval * 2);
        }
        &-3 {
            margin-left: ($cval * 3);
        }
        &-4 {
            margin-left: ($cval * 4);
        }
        &-5 {
            margin-left: ($cval * 5);
        }
        &-6 {
            margin-left: ($cval * 6);
        }
        &-7 {
            margin-left: ($cval * 7);
        }
        &-8 {
            margin-left: ($cval * 8);
        }
        &-9 {
            margin-left: ($cval * 9);
        }
        &-7pm {
            margin-left: -7px;
        }
        &-47pm {
            margin-left: -47px;
        }
    }
}

// Z-Indexes
// =================================
.z{
  &-1 {
    z-index: 1;
  }
  &-2 {
    z-index: 2;
  }
  &-3 {
    z-index: 3;
  }
}

// Text Alignments
// =================================
.text {
    &-left {
        text-align: left;
    }
    &-right {
        text-align: right;
    }
    &-center {
        text-align: center;
    }
    &-bold {
        font-weight: bold;
    }
    &-capitalize {
        text-transform: capitalize;
    }
    &-lowercase {
        text-transform: lowercase;
    }
    &-uppercase {
        text-transform: uppercase;
    }
    &-area{
        width:18.75em;
        &-16{
            width:16.66em;
        }
    }
}

// Float
// =================================
.float {
    &-left {
        float: left;
    }
    &-right {
        float: right;
    }
    &-none {
        float: none;
    }
}

// Display
// =================================
.d {
    &-none {
        display: none;
    }
    &-inline {
        display: inline;
    }
    &-block {
        display: block;
    }
    &-inline-block {
        display: inline-block;
    }
}

// cursor
// =================================
.cursor {
    &-hand {
        cursor: pointer;
    }
}
// Overflow
// =================================
.o {
    &-auto {
        overflow: auto;
    }
    &-scroll {
        overflow: scroll;
    }
    &-hidden {
        overflow: hidden;
    }
    &-visible {
        overflow: visible;
    }
    &-x {
        &-auto {
            overflow-x: auto;
        }
        &-scroll {
            overflow-x: scroll;
        }
        &-hidden {
            overflow-x: hidden;
        }
        &-visible {
            overflow-x: visible;
        }
    }
    &-y {
        &-auto {
            overflow-y: auto;
        }
        &-scroll {
            overflow-y: scroll;
        }
        &-hidden {
            overflow-y: hidden;
        }
        &-visible {
            overflow-y: visible;
        }
    }
}
// Border
// =================================
.b {
    &-a{
        border:none;
        &-1p{
            border:1px solid $grey-ultra-dark;
        }
    }
    &-b {
        &-none {
            border-bottom: none;
        }
    }
    &-t{
        &-none {
            border-top: none;
        }
        &-1p{
            border-top:1px solid $grey-dark;
        }
    }
    &-r{
        &-none{
            border-right:none;
        }
    }
    &-l{
        &-none{
            border-left:none;
        }
    }
}
// Width
// =================================
.w {
    &-5pe {
        width: 5%;
    }
    &-9pe {
        width: 9%;
    }
    &-10pe {
        width: 10%;
    }
    &-90pe {
        width: 90%;
    }
    &-100pe {
        width: 100%;
    }
    &-96p {
        width: 96px;
    }
    &-100p {
        width: 100px;
    }
    &-133p {
        width: 133px;
    }
    &-150p {
        width: 150px;
    }
    &-176p {
        width: 176px;
    }
    &-200p {
        width: 200px;
    }
    &-266p {
        width: 266px;
    }
    &-12p {
        width: 12px;
    }
    &-500p{
        width:500px;
    }
    &-100{
        width: 100%;
    }
}
// Min Width
// =================================
.min-width{
    &-fc{
        min-width: -webkit-fill-available;
        min-width: -moz-fit-content;
        min-width: fit-content;
    }
}
// Max Width
// =================================
.max-width{
    &-fc{
        max-width: -webkit-fill-available;
        max-width: -moz-fit-content;
        max-width: fit-content;
    }
}
.max-width{
    &-mc{
        max-width: min-content;
    }
    &-30{
      max-width: 30vw;
    }
}
// vertical Align
// =================================
.v {
    &-a {
        &-t {
            vertical-align: top;
        }
        &-m {
            vertical-align: middle;
        }
        &-b {
            vertical-align: bottom;
        }
        &-tt{
            vertical-align: text-top;
        }
        &-tb{
            vertical-align: text-bottom;
        }
        &-baseline{
            vertical-align: baseline;
        }
    }
}
// Top
// =================================
.t {
    &-05p {
        top: 0.5px;
    }
    &-12pem {
        top: -12%;
    }
    &-32p{
        top: 32px;
    }
    &-065pem{
        top: 0.65em;
    }
}
// Color
// =================================
.c {
    &-white {
        color: $white;
    }
    &-black {
        color: $black;
    }
    &-green{
        color:$primary-green;
    }
    &-blue{
        color:$secondary-blue;
    }
}
// Height
// =================================
.h {
    &-20p {
        height: 20px;
    }
    &-25p {
        height: 25px;
    }
    &-64p {
        height: 64px;
    }
    &-150p {
        height: 150px;
    }
    &-2e {
        height: 2em;
    }
    &-200p{
        height: 200px;
    }
}
// focus
// =================================
.f{
    &-none{
        outline:none;
    }
}

// Position
// =================================
.pos{
    &-relative{
        position:relative;
    }
    &-absolute{
        position: absolute;
    }
    &-fixed{
        position:fixed;
    }
}
// flex
// =================================
.flex{
    &-center{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-start{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    &-end{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &-center-col{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
// Word Break
// =================================
.w{
    &-b{
        &-a{
            word-break:break-word;
        }
    }
}
// BACKGROUNDS
// =================================
.bg{
    &-red{
        background-color:$red;
    }
    &-green{
        background-color:$primary-green;
    }
    &-yellow{
        background-color: $yellow-dark;
    }
    &-none{
        background-color: none;
    }
}
// Generic Message in center of the screen
// =================================
.generic-message-center{
    text-align: center;
    margin-top: 15%;
    width: 100%;
    p{
        font-weight: bold;
        font-size: 16px;
    }
}

//Line-height
.lh{
    &-13p{
        line-height: 13px;
    }
}

//Right
.r{
    &-6p{
        right: 6px;
    }
}

// White Space
.ws{
    &-nw{
        white-space: nowrap;
    }
}

// text overflow ellipsis
.text-ellipsis {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}
