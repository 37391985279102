@import "./theme";

// Border
$box-shadow: 0 2px 4px 0 rgb(0 0 0 / 3%), 0 4px 5px 0 rgb(0 0 0 / 4%);
$border-color: rgba(0, 0, 0, 0.08);

// Transition
$background-color-transition: background-color 0.5s ease;
$color-transition: color 0.5s ease;

// Text
$T1: 48px;
$T1-1000: 42px;
$T1-800: 37px;
$T1-600: 32px;
$T1-400: 28px;

$T2: 32px;
$T2-1000: 30px;
$T2-800: 26px;
$T2-600: 24px;
$T2-400: 22px;

$T3: 26px;
$T3-1000: 24px;
$T3-800: 22px;
$T3-600: 20px;
$T3-400: 20px;

$T4: 22px;
$T4-1000: 20px;
$T4-800: 18px;
$T4-600: 16px;
$T4-400: 16px;

$T5: 16px;
$T5-1000: 15px;
$T5-800: 14px;
$T5-600: 14px;
$T5-400: 14px;

$T6: 14px;
$T6-1000: 13px;
$T6-800: 12px;
$T6-600: 12px;
$T6-400: 12px;

$T7: 12px;
$T7-1000: 11px;
$T7-800: 10px;
$T7-600: 10px;
$T7-400: 10px;

$T8: 10px;
$T8-1000: 9px;
$T8-800: 8px;
$T8-600: 8px;
$T8-400: 8px;

$T9: 8px;
$T9-1000: 8px;
$T9-800: 8px;
$T9-600: 8px;
$T9-400: 8px;

[class*="Txt"] {
    color: $primary-text;
}

.Txt{
    &1 {
        font-size: $T1;
    }
    &2 {
        font-size: $T2;
    }
    &3 {
        font-size: $T3;
    }
    &4 {
        font-size: $T4;
    }
    &5 {
        font-size: $T5;
    }
    &6 {
        font-size: $T6;
    }
    &7 {
        font-size: $T7;
    }
    &8 {
        font-size: $T8;
    }
    &9 {
        font-size: $T9;
    }
}

// Page
$small-textbox-maxwidth: 40vw;
$small-textbox-maxwidth-1000: 45vw;
$small-textbox-maxwidth-800: 50vw;
$small-textbox-maxwidth-600: 55vw;
$small-textbox-maxwidth-400: 60vw;

$medium-textbox-maxwidth: 50vw;
$medium-textbox-maxwidth-1000: 55vw;
$medium-textbox-maxwidth-800: 60vw;
$medium-textbox-maxwidth-600: 65vw;
$medium-textbox-maxwidth-400: 70vw;

$large-textbox-maxwidth: 60vw;
$large-textbox-maxwidth-1000: 65vw;
$large-textbox-maxwidth-800: 70vw;
$large-textbox-maxwidth-600: 75vw;
$large-textbox-maxwidth-400: 80vw;

// Buttons
$small-button-width: 110px;
$small-button-width-1000: 100px;
$small-button-width-800: 90px;
$small-button-width-600: 80px;
$small-button-width-400: 70px;

$small-button-height: 45px;
$small-button-height-1000: 40px;
$small-button-height-800: 35px;
$small-button-height-600: 30px;
$small-button-height-400: 25px;

$medium-button-width: 125px;
$medium-button-width-1000: 120px;
$medium-button-width-800: 110px;
$medium-button-width-600: 100px;
$medium-button-width-400: 90px;

$medium-button-height: 35px;
$medium-button-height-1000: 35px;
$medium-button-height-800: 30px;
$medium-button-height-600: 30px;
$medium-button-height-400: 25px;

$large-button-width: 250px;
$large-button-width-1000: 200px;
$large-button-width-800: 150px;
$large-button-width-600: 150px;
$large-button-width-400: 125px;

$large-button-height: 40px;
$large-button-height-1000: 40px;
$large-button-height-800: 40px;
$large-button-height-600: 40px;
$large-button-height-400: 35px;

// AppBar
$appbar-height: 50px;
$appbar-height-1000: 50px;
$appbar-height-800: 50px;
$appbar-height-600: 50px;
$appbar-height-400: 50px;

$appbar-margin-right-1000: 10px;
$appbar-margin-right-800: 15px;
$appbar-margin-right-600: 20px;
$appbar-margin-right-400: 25px;

$appbar-logo-height: 40px;
$appbar-logo-height-1000: 40px;
$appbar-logo-height-800: 40px;
$appbar-logo-height-600: 40px;
$appbar-logo-height-400: 40px;

$appbar-segment-width: 100px;
$appbar-segment-width-1000: 80px;
$appbar-segment-width-800: 60px;
$appbar-segment-width-600: 50px;
$appbar-segment-width-400: 40px;

$appbar-spacer-end: 20px;
$appbar-spacer-end-1000: 15px;
$appbar-spacer-end-800: 10px;
$appbar-spacer-end-600: 10px;
$appbar-spacer-end-400: 5px;

// Component Wrapper
$wrapper-width: 600px;
$wrapper-width-1000: 500px;
$wrapper-width-800: 400px;
$wrapper-width-600: 350px;
$wrapper-width-400: 325px;

// Scheduler
$schedule-cell-width: 65px;
$schedule-cell-width-1000: 60px;
$schedule-cell-width-800: 55px;
$schedule-cell-width-600: 50px;
$schedule-cell-width-400: 40px;

$schedule-cell-height: 30px;
$schedule-cell-height-1000: 25px;
$schedule-cell-height-800: 20px;
$schedule-cell-height-600: 15px;
$schedule-cell-height-400: 15px;

// Attendant Card
$attendant-card-height: 150px;
$attendant-card-height-1000: 140px;
$attendant-card-height-800: 135px;
$attendant-card-height-600: 125px;
$attendant-card-height-400: 125px;

// Attendant Profile
$picture-column-min-width: 250px;
$picture-column-min-width-1000: 225px;
$picture-column-min-width-800: 210px;
$picture-column-min-width-600: 200px;
$picture-column-min-width-400: 180px;

$details-column-min-width: 400px;
$details-column-min-width-1000: 375px;
$details-column-min-width-800: 360px;
$details-column-min-width-600: 350px;
$details-column-min-width-400: 325px;

$profile-picture: 200px;
$profile-picture-1000: 180px;
$profile-picture-800: 175px;
$profile-picture-600: 175px;
$profile-picture-400: 160px;

$window-width: 600px;
$window-width-1000: 575px;
$window-width-800: 550px;
$window-width-600: 550px;
$window-width-400: 400px;

// Home
$logo-image-height: 75px;
$logo-image-height-1000: 70px;
$logo-image-height-800: 60px;
$logo-image-height-600: 50px;
$logo-image-height-400: 40px;

$logo-image-width: 150px;
$logo-image-width-1000: 135px;
$logo-image-width-800: 130px;
$logo-image-width-600: 125px;
$logo-image-width-400: 110px;

$logo-image-spacer: 225px;
$logo-image-spacer-1000: 200px;
$logo-image-spacer-800: 185px;
$logo-image-spacer-600: 175px;
$logo-image-spacer-400: 165px;

// Attendant Search
$popup-width: 300px;
$popup-width-1000: 275px;
$popup-width-800: 260px;
$popup-width-600: 250px;
$popup-width-400: 250px;
