$primary-green: #6ebf4b;
$secondary-blue: #006df0;
$white: #ffffff;
$blue-ultra-lightest: #fafaff;
$blue-ultra-lighter: #e0ffff;
$blue-ultra-light: #e6f2ff;
$blue-light: #a0c5fd;
$blue-dark: #0d4c92;
$blue-darker: #112145;
$blue-ultra-dark: #2c3e50;
$black: #000000;
$grey: #c1c1c1;
$grey-light: #d5d5d5;
$grey-lighter: #dfdfdf;
$grey-ultra-light: #f5f5f5;
$grey-ultra-lighter: #fafafa;
$grey-dark: #c5c5c5;
$grey-darker: #adabab;
$grey-darkest: #565656;
$grey-ultra-dark: #939393;
$grey-ultra-darker: #343434;
$green: #008000;
$green-light: #6dbf4b;
$red: #ff0000;
$red-light: #ff6f7e;
$yellow-light: #f5e876;
$yellow-dark: #f2cb1d;
$transparent: transparent;
$transparent-20p: rgba(0, 0, 0, 0.80);
$transparent-25p: rgba(0, 0, 0, 0.75);
$transparent-30p: rgba(0, 0, 0, 0.70);
$transparent-35p: rgba(0, 0, 0, 0.65);
$transparent-40p: rgba(0, 0, 0, 0.60);
$transparent-45p: rgba(0, 0, 0, 0.55);
$transparent-50p: rgba(0, 0, 0, 0.50);
$transparent-55p: rgba(0, 0, 0, 0.45);
$transparent-60p: rgba(0, 0, 0, 0.40);
$transparent-65p: rgba(0, 0, 0, 0.35);
$transparent-70p: rgba(0, 0, 0, 0.30);
$transparent-75p: rgba(0, 0, 0, 0.25);
$transparent-80p: rgba(0, 0, 0, 0.20);
$transparent-85p: rgba(0, 0, 0, 0.15);
$transparent-90p: rgba(0, 0, 0, 0.10);
$transparent-95p: rgba(0, 0, 0, 0.05);
$red-transparent-30p:  rgba(255, 99, 71, 0.3);

//legacy misspellings
$transperant-50p: $transparent-50p;
$transperant-20p: $transparent-20p;
$transperant-95p: $transparent-95p;

// Opaque Colors
$primary-green-opaque-3: rgba(110, 191, 75, 0.25);
$blue-dark-opaque-3: rgba(13, 76, 146, 0.25);