@font-face {
	font-family: 'cdmsfonts';
    src: url('../../assets/fonts/cdmsfonts.eot');
	src: url('../../assets/fonts/cdmsfonts.eot') format('embedded-opentype'),
		url('../../assets/fonts/cdmsfonts.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
    font-family: "Segoe UI", "Segoe UI SemiBold";
    src: url("../../assets/fonts/SEGOEUI.TTF");
}
@font-face {
    font-family: "Segoe UI SemiBold";
    src: url("../../assets/fonts/seguisb.ttf");
}
$primary-font: "Segoe UI", "Segoe UI SemiBold";

* {
	font-family: $primary-font;
}