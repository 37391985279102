@import "~@ionic/angular/css/core.css";

@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./assets/scss/pages";
@import "./assets/scss/fonts";
@import "./assets/scss/variables";
@import "./assets/scss/theme";
@import "./assets/scss/generics";
